<template>
  <div id="app">
    <Navbar/>

    <router-view></router-view>

    <Footer/>
  </div>
</template>

<script>
    import Navbar from './components/Navbar';
    import Footer from './components/Footer';

    export default {
        metaInfo: {
            titleTemplate: '%s | eletokosan.hu',
        },

        components: {
            'Navbar': Navbar,
            'Footer': Footer,
        },
    }
</script>

<style scooped>
#app, body {
  font-family: 'Open Sans',arial,sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
}

h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 27px;
}

h3 {
    font-weight: 700;
    font-size: 28px;
    line-height: 50px;
}

h4 {
    font-weight: 700;
    font-size: 26px;
    line-height: 50px;
}

h5 {
    font-weight: 700;
    font-size: 24px;
    line-height: 50px;
}

h6 {
    font-weight: 700;
    font-size: 22px;
    line-height: 50px;
}

a {
    color: #12a19a;
}

a:hover {
    color: #12a19a;
    opacity: .8;
    text-decoration: underline;
}

h1.title {
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
}

h2.title {
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
}

.btn.btn-secondary {
    background: #12a19a;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF !important;
    border-radius: 0;
    border: none;
    padding: 0.375rem 1.25rem;
}

.btn.btn-secondary:hover, .btn.btn-secondary:active {
    background: #12a19a;
    opacity: .7;
    border-radius: 0;
    border: none;
}

b {
    font-weight: bold !important;
}

p {
    margin-bottom: 0.75rem;
}

.divider {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
}

.divider-line {
    width: 30px;
    margin: 0 auto;
    border-top: 5px solid #12a19a;
    display: flex;
}

</style>
