import Homepage from './views/Homepage.vue';
import Blog from './views/Blog.vue';
import Tag from './views/Tag.vue';
import Article from './views/Article.vue';
import Contact from './views/Contact.vue';
import NotFound from './views/NotFound.vue';

export default [
    { path: '/', name: 'homepage',  component: Homepage },
    { path: '/okosotthon-blog', name: 'blog',  component: Blog },
    { path: '/kapcsolat', name: 'contact', component: Contact },
    { path: '/404', name: 'notfound', component: NotFound },
    { path: '/:publication', name: 'article',  component: Article },
    { path: '/tag/:tag', name: 'tag',  component: Tag },
]