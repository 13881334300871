<template>
    <footer class="page-footer">
        <div class="footer-top text-center">
            <img src="/images/eletokosan-logo.png" alt="eletokosan" class="mb-2">
            <a href="https://www.facebook.com/eletokosan/" target=_blank class="social-icon">
                <i class="fab fa-facebook-square d-block"></i>
            </a>
        </div>
        <div class="footer-copyright text-center">
            © eletokosan.hu | <a href="https://itzen.hu/" target="_blank">A weboldalt készítette az ITZen</a>
        </div>
    </footer>
</template>

<script>
export default {
    
}
</script>

<style>
.page-footer {
    background: #343a40;
}

.footer-top {
    padding: 1rem 2rem;
}

.footer-top img {
    max-width: 300px;
}

.footer-copyright {
    background:#2f2f2f;
    color: #fff;
}

.footer-copyright a {
    color: #12a19a;
}

.footer-copyright a:hover {
    color: #12a19a;
    text-decoration: underline;
}

.social-icon {
    font-size: 2rem;
    color: #fff !important;
}

.social-icon:hover {
    color: #12a19a !important;
}

.social-icon svg {
    margin: 0 auto;
}
</style>