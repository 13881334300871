<template>
    <b-container>
        <h1 class="page-title text-center mt-4">Blog</h1>

        <div class="divider">
            <span class="divider-line"></span>
        </div>        

            <b-row class="articles my-4">
                <PostCard :publication="publication"
                    v-for="publication in publications"
                    v-bind:key="publication.id"/>
            </b-row>

            <b-pagination
                v-model="page"
                :total-rows="count"
                :per-page="pageSize"
                @change="handlePageChange"
            ></b-pagination>            

    </b-container>    
</template>

<script>
import PostCard from '../components/PostCard'

export default {
    data() {
        return {
            publications: [],
            page: 1,
            count: 0,
            pageSize: 9,
            description: '',
            title: '',                                          
        }
    },

    metaInfo () {
        return {
            title: this.title,
            meta: [
                { vmid: 'description', name: 'description', content: this.description }
            ]
        }
    },    

    created(){
        this.$http.get(`/publications/page/okosotthon-blog`)
            .then(results => {
                this.description = results.data.description
                this.title = results.data.title
            }),        
        this.handlePageChange(1)
    },

    methods: {
        handlePageChange(value) {
            this.page = value;

            this.$http.get(`/publications/post?sort=['published_at', 'desc']`, {
                params: {
                    with: 'category,tags,author,content,firstImage',
                    page: this.page,
                    size: this.pageSize
                }
            }).then(results => {
                this.publications = results.data.data
                this.loaded = true
                this.count = results.data.meta.total
            })
        },
    },    

    components: {
        'PostCard': PostCard,
    }
}    
</script>

<style>
.paginate-links li.page-item {
    cursor: pointer;
}

.page-item.active .page-link {
    color: #fff !important;
    background-color: #12a19a !important;
    border-color: #12a19a !important;
}

.page-link {
    color: #fff !important;
    border: 1px solid #343a40 !important;
    background-color: #343a40 !important;
}

.page-link:hover {
    color: #fff !important;
    border: 1px solid #12a19a !important;
    background-color: #12a19a !important;
}

.page-item.disabled .page-link {
    color: #fff !important;
    background-color: #343a40 !important;
    border-color: #343a40 !important;
    opacity: 0.9 !important;
}

.disabled.page-item {
    cursor: default !important;
}
</style>