<template>
    <div>
        <b-navbar toggleable="lg" type="dark" variant="dark">
            <router-link class="navbar-brand" to="/">
                <img src="/images/eletokosan-logo.png" alt="eletokosan.hu">
            </router-link> 

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav  class="ml-auto">
                    <router-link class="nav-link" to="/">Főoldal</router-link>
                    <router-link class="nav-link" to="/okosotthon-blog">Blog</router-link>
                    <router-link class="nav-link" to="/kapcsolat">Kapcsolat</router-link>
                    <a href="https://www.facebook.com/eletokosan/" target="_blank" class="nav-link">Okosközösség<i class="fas fa-external-link-alt ml-2"></i></a>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        methods: {
            fetchTitles(){
                axios.get('/api/blocks/titles', {
                    params: {
                        filters: {
                            site: this.site,
                        }
                    }
                })
                .then(results => {
                    this.titles = results.data.data
                })
            },
        },     
    }
</script>

<style>
.navbar {
    padding: 1rem 2rem;
}

.navbar-dark .navbar-nav .nav-link {
    font-family: 'Open Sans',arial,sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    color: #fff !important;
    text-transform: uppercase;
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .router-link-exact-active {
    font-family: 'Open Sans',arial,sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    color: #12a19a !important;   
}

a.navbar-brand img {
    max-width: 300px;
}

@media screen and (min-width: 1200px) {
    .navbar-dark .navbar-nav .nav-link {
        padding-right: 2rem !important;
        padding-left: 2rem !important;
    }
}
</style>