import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import Routes from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueGtag from "vue-gtag"
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import VueMeta from 'vue-meta'

Vue.config.productionTip = false
Vue.use(VueRouter)

Vue.use(BootstrapVue)

Vue.use(VueGtag, {
  config: { 
    id: "UA-80767339-12", params: {
      send_page_view: true
    }
  }
},router);

Vue.use(VueMeta)

const router = new VueRouter({
  routes: Routes,
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
})

axios.interceptors.request.use(function (config) {
  var AUTH_TOKEN = ''

  if (process.env.NODE_ENV == 'production') {
    AUTH_TOKEN = '223KUeayYFJj14mzHwF923exctLMwYZ2QEtrGuqtn77y3oHMrX0JTVmNaTWgrewPqduIWnM43jGC3IwYuvHYA4BBzv'
    config.baseURL = 'https://homevacuumcleaner.info/api'
  } else {
    AUTH_TOKEN = '123456789'
    config.baseURL = 'http://localhost:8000/api'
  }
  
  if (AUTH_TOKEN) {
    config.headers.common['Authorization'] = `Bearer ${AUTH_TOKEN}`
  }

  return config
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})

Vue.use(VueAxios, axios)

new Vue({
  render: h => h(App),
  router: router
}).$mount('#app')
