<template>
    <b-container fluid v-if="publication">

        <b-jumbotron fluid v-bind:style="{ 'background-image': 'url(' + imageUrl + ')' }">
            <template #header>
                {{ publication.title }}
                
                <div class="divider">
                    <span class="divider-line"></span>
                </div>                           
            </template>

            <template #lead>
                <span class="mx-2 article-meta">
                    <i class="far fa-clock"></i> Publikálva: {{ publication.published_at }}
                </span>
                <span class="mx-2 article-meta" v-if="publication.relations.tags[0]">
                    <i class="fas fa-tag"></i> Címke: <span v-for="tag in publication.relations.tags" v-bind:key="tag.id" class="mr-2"><router-link :to="'tag/' + tag.slug">{{ tag.name }}</router-link></span>
                </span>
            </template>
        </b-jumbotron>
        
        <b-container>
            <editor-js-block v-for="(block, index) in sections"
                v-bind:key="index"
                :block="block.content">
            </editor-js-block>
        </b-container>

        <b-container class="pt-5">
            <h2 class="text-center mt-4">Legfrissebb bejegyzések</h2>

            <div class="divider">
                <span class="divider-line"></span>
            </div>

            <b-row class="articles">
                <PostCard :publication="publication"
                    v-for="publication in publications"
                    v-bind:key="publication.id"/>
            </b-row>            
        </b-container>        
        
        <b-container>
            <Comment />
        </b-container>                       

    </b-container>       
</template>

<script>
    import EditorJsBlock from '../components/EditorJsBlock'
    import Comment from '../components/Comment'
    import PostCard from '../components/PostCard'    


    export default {
        data() {
            return {  
                publication: null,
                description: '',
                title: '',
                publications: [],
                loaded: false,
            }
        },

        metaInfo () {
            return {
                title: this.title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.description }
                ]
            }
        },   

        components: {
            EditorJsBlock,
            'Comment': Comment,
            'PostCard': PostCard,
        },

        computed: {
            url(){
                return `/${this.publication.slug}`
            },
            imageUrl(){
                let url = this.publication?.content[0]?.content?.data?.url

                return url ? url : '/images/default-featured-image.jpg'
            },
            sections(){
                var hasImage = false //Alapból hamis, hogy van képünk

                return this.publication.content.filter(item => { //Létrehozunk egy szűrőt a tartalomban
                    if (item.content.type != 'image') { //Ha a tartalom típusunk nem egyenlő a képpel
                        return true //Átállítjuk igazra
                    } else { //Ellenben
                        if (hasImage){ //Ha volt már képunk
                            return true //Átállítjuk igazra
                        } else { //Ellenben
                            hasImage = true //Igaz, hogy van képünk 

                            return false //Vissza állítjuk hamisra
                        }
                                          
                    } 
                  })
            },

        },                 

        methods: {
            fetchPublication(){
                this.$http.get(`/publications/post/${this.$route.params.publication}`, {
                    params: {
                        with: 'category,tags,author,content',
                    }
                }).then(response => {
                    this.publication = response.data
                    this.description = response.data.description
                    this.title = response.data.title
                    this.loaded = this.$route.params.publication
                }).catch(error => {
                    if (error.response.status === 404) {
                        window.location.href = '/404';
                    } else {
                        console.log(error)
                    }                  
                })
            },                    
        },

        created(){
            this.fetchPublication(),

            this.$http.get(`/publications/post?sort=['published_at', 'desc']`, {
                params: {
                    with: 'category,tags,author,firstImage'
                }
            }).then(results => {
                this.publications = results.data.data.slice(0, 3)
            })             
        },
        watch: {
            $route(to){
                if(to.params.publication != this.loaded){
                    this.fetchPublication()
                }
            }
        } 
    }   
</script>

<style>
@media screen and (max-width: 576px){
    .article-meta {
        display: block;
        margin: 10px 0px;
    }
}

.comment {
    margin: 4rem 0rem;
}

.display-3 {
    font-size: 3rem !important;
    font-weight: 500 !important;
}
</style>