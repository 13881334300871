<template>
        <div class="row comment">
            <div class="col">
                <div id="disqus_thread"></div>
            </div>
        </div>
</template>

<script>
export default {
    data(){
        return {
            disqus_config: null,
        }
    },

    mounted: function() {
        (function () {
            this.page.url = window.location.href;
            this.page.identifier = window.location.href;
        });

        (function() {
            var d = document, s = d.createElement('script');

            s.src = 'https://eletokosan.disqus.com/embed.js';

            s.setAttribute('data-timestamp', +new Date());
            (d.head || d.body).appendChild(s);
        })();
    },
}
</script>

<style scoped>

</style>