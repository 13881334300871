<template>
    <b-container fluid v-if="page">
        <b-jumbotron fluid v-for="(section, i) in sections.slice(0, 1)" :key="i" v-bind:style="{ 'background-image': 'url(' + section[0].data.url + ')' }">
            <template #header>
                <editor-js-block v-for="(block, index) in sections[1]"
                    :key="index"
                    :block="block">
                </editor-js-block>
                
                <div class="divider">
                    <span class="divider-line"></span>
                </div>                           
            </template>

            <template #lead>
                <editor-js-block v-for="(block, index) in sections[2]"
                    :key="index"
                    :block="block">
                </editor-js-block>  
            </template>
        </b-jumbotron>

        <b-container>
            <h2 class="text-center mt-4">Legfrissebb bejegyzések</h2>

            <div class="divider">
                <span class="divider-line"></span>
            </div>

            <b-row class="articles my-4">
                <PostCard :publication="publication"
                    v-for="publication in publications"
                    v-bind:key="publication.id"/>
            </b-row>
        </b-container>

    </b-container>
</template>

<script>
    import EditorJsBlock from '../components/EditorJsBlock'
    import PostCard from '../components/PostCard'

    export default {
        data() {
            return {
                page: null,
                publications: [],
                description: '',
                title: '',                                                        
            }
        },

        metaInfo () {
            return {
                title: this.title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.description }
                ]
            }
        },         

        components: {
            EditorJsBlock,
            'PostCard': PostCard,
        },

        computed: {
            sections(){
                if(! this.page){
                    return []
                }

                var sections = []
                var currentSection = 0
                for (const i in this.page) {
                    if(! sections[currentSection]){
                        sections.push([])
                    }

                    var block = this.page[i]

                    if(block.type=='delimiter'){
                        currentSection++
                        continue
                    }

                    if(block.type=='header'){
                        block.data.class='title'
                    }
                    sections[currentSection].push(block)
                }

                return sections
            }
        },    

        created(){
            this.$http.get(`/publications/page/fooldal`)
                .then(results => {
                    this.page = results.data.content.map(o => o.content)
                    this.description = results.data.description
                    this.title = results.data.title
                })

            this.$http.get(`/publications/post?sort=['published_at', 'desc']`, {
                params: {
                    with: 'category,tags,author,firstImage'
                }
            }).then(results => {
                this.publications = results.data.data.slice(0, 6)
            })                
        },
    }
</script>

<style>
.jumbotron-fluid {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #4040409e !important;
    background-blend-mode: overlay;
    color: #fff;
    text-align: center;
}

.jumbotron-fluid a {
    color: #12a19a;
}

.jumbotron-fluid a:hover {
    color: #12a19a;
}

.container-fluid {
    padding-right: 0px !important;
    padding-left: 0px !important;
}
</style>