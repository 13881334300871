<template>
    <b-container>
        <h1 class="page-title text-center mt-4">Címke: {{ tag.name }}</h1>

        <div class="divider">
            <span class="divider-line"></span>
        </div>
        
        <b-container v-if="description" class="text-center">
            <editor-js-block v-for="(block, index) in description"
                v-bind:key="index"
                :block="block.content">
            </editor-js-block>
        </b-container>

        <b-row class="articles my-4">
            <PostCard :publication="publication"
                v-for="publication in tag.relations.publications"
                v-bind:key="publication.id"/>
        </b-row>

        <b-pagination
            v-model="page"
            :total-rows="count"
            :per-page="pageSize"
            @change="handlePageChange"
        ></b-pagination>   

    </b-container>    
</template>

<script>
import PostCard from '../components/PostCard'
import EditorJsBlock from '../components/EditorJsBlock'

export default {
    data() {
        return {
            tag: [],
            page: 1,
            count: 0,
            pageSize: 9,
            title: '',
            description: '',
        }
    },

    metaInfo () {
        return {
            title: 'Címke: ' + this.tag.name,
        }
    },    

    components: {
        'PostCard': PostCard,
        EditorJsBlock,
    },    

    methods: {
        handlePageChange(value) {
            this.page = value;

            this.$http.get(`/tags/${this.$route.params.tag}?sort=['published_at', 'desc']`, {
                params: {
                    with: 'publications,firstImage,description',
                    page: this.page,
                    size: this.pageSize
                }
            }).then(response => {
                this.tag = response.data.data
                this.description = response.data.data.relations.description
                this.loaded = true
                this.count = response.data.meta.total
            }).catch(error => {
                if (error.response.status === 404) {
                    window.location.href = '/404';
                } else {
                    console.log(error)
                }                  
            })
        },                
    },

    created(){
        this.handlePageChange(1)
    },     

}    
</script>

<style>
.paginate-links li.page-item {
    cursor: pointer;
}

.page-item.active .page-link {
    color: #fff !important;
    background-color: #12a19a !important;
    border-color: #12a19a !important;
}

.page-link {
    color: #fff !important;
    border: 1px solid #343a40 !important;
    background-color: #343a40 !important;
}

.page-link:hover {
    color: #fff !important;
    border: 1px solid #12a19a !important;
    background-color: #12a19a !important;
}

.page-item.disabled .page-link {
    color: #fff !important;
    background-color: #343a40 !important;
    border-color: #343a40 !important;
    opacity: 0.9 !important;
}

.disabled.page-item {
    cursor: default !important;
}
</style>