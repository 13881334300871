<template>
    <b-container v-if="page">
        <h1 class="page-title text-center mt-4">Kapcsolat</h1>

        <div class="divider">
            <span class="divider-line"></span>
        </div>

        <b-row class="contact my-4">
            <b-col md="6" class="contact-form">
                <form>
                    <b-form-input v-model="form.name" 
                        placeholder="Név">
                    </b-form-input>
                    
                    <b-form-input v-model="form.email"
                        id="email" 
                        placeholder="E-mail">
                    </b-form-input>
                    
                    <b-form-textarea id="textarea"
                        v-model="form.text"
                        placeholder="Üzenet"
                        rows="3"
                        max-rows="6">
                    </b-form-textarea>

                    <b-button type="submit" variant="primary" class="btn btn-secondary">Küldés</b-button>
                </form>                
            </b-col>
            <b-col md="6" class="contact-description">
                <editor-js-block v-for="(block, index) in sections[0]"
                    :key="index"
                    :block="block">
                </editor-js-block>
            </b-col>            
        </b-row>        
    </b-container>    
</template>

<script>
import EditorJsBlock from '../components/EditorJsBlock'

export default {
    data() {
        return {
            page: null,
            description: '',
            title: '',
            form: {
                name: '',
                email: '',
                text: '',
            },                 
        }
    },

    metaInfo () {
        return {
            title: this.title,
            meta: [
                { vmid: 'description', name: 'description', content: this.description }
            ]
        }
    },      

    components: {
        EditorJsBlock
    },

    computed: {
        sections(){
            if(! this.page){
                return []
            }

            var sections = []
            var currentSection = 0
            for (const i in this.page) {
                if(! sections[currentSection]){
                    sections.push([])
                }

                var block = this.page[i]

                if(block.type=='delimiter'){
                    currentSection++
                    continue
                }

                if(block.type=='header'){
                    block.data.class='title'
                }
                sections[currentSection].push(block)
            }

            return sections
        }
    },        

    created(){
        this.$http.get(`/publications/page/kapcsolat`)
            .then(results => {
                this.page = results.data.content.map(o => o.content)
                this.description = results.data.description
                this.title = results.data.title
            })
    }
}
</script>

<style>
.form-control {
    border-radius: 0 !important;
    border: 1px solid #000000 !important;
    background: #fff !important;
    color: #000000 !important;
    margin: 10px 0px !important;
}

.contact-description {
    background: #FFFFFF;
    box-shadow: 4px 4px 15px rgba(0,0,0,.15);
    padding: 20px;
    display: grid;
    align-content: center;
}

.contact-form {
    padding: 20px;
    display: grid;
    align-content: center;
}
</style>