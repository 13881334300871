<template>
    <b-col md="6" lg="4">
        <div class="article">
            <router-link :to="url">
                <b-card :title="publication.title"
                    :img-src="imageUrl"
                    img-top>
                    <b-card-text>
                        {{ publication.description.slice(0, 130) }}...
                    </b-card-text>
                </b-card>
            </router-link>
        </div>        
    </b-col>    
</template>

<script>

export default {
    props: {
        'publication': {required: true, type: [Object]}
    },
    computed:{
        url(){
            return `/${this.publication.slug}`
        },
        imageUrl(){
            let url = this.publication.relations.firstImage?.content?.data?.url

            return url ? url : '/images/default-featured-image.jpg'
        },
        sections(){
            if(! this.page){
                return []
            }

            var sections = []
            var currentSection = 0
            for (const i in this.page) {
                if(! sections[currentSection]){
                    sections.push([])
                }

                var block = this.page[i]

                if(block.type=='delimiter'){
                    currentSection++
                    continue
                }

                if(block.type=='header'){
                    block.data.class='title'
                }
                sections[currentSection].push(block)
            }

            return sections
        }
    }
}
</script>

<style>
.article {
    margin: 15px 0;
}

.card {
    background: #FFFFFF;
    box-shadow: 4px 4px 15px rgba(0,0,0,.15);
    border-radius: 0 !important;
    border: none !important;    
}

img.card-img-top {
    border-radius: 0;
}

h4.card-title {
    font-family: 'Open Sans',arial,sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 100%;
    color: #000000;    
}

.card-body {
    display: grid;
    align-self: flex-start;
    width: 100%;
}

.card {
    display: grid;
    min-height: 360px;
}

.card-text {
    font-family: 'Open Sans',arial,sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: #000000;    
}

.article a .card {
    transition: all .2s ease-in-out;
}

.article a:hover {
    text-decoration: none !important;
}

.article a:hover .card {
    text-decoration: none !important;
    transform: scale(1.02);
}

.article a:hover .card-title {
    color: #12a19a !important;
    transition: all .2s ease-in-out;
}
</style>