<template>
    <b-container class="text-center">
        <h1 class="page-title text-center mt-4">404</h1>

        <div class="divider">
            <span class="divider-line"></span>
        </div>

        <h2 class="my-5">Oops! A keresett oldal nem található!</h2>

        <router-link class="btn btn-secondary mb-4" to="/">
          <i class="fa fa-arrow-left mr-2"></i>Vissza a főoldalra
        </router-link>
    </b-container>    
</template>

<script>
export default {
    data() {
        return {
            title: '404',                                                        
        }
    },

    metaInfo: {
      title: '404'
    }        
}
</script>